<template>
  <div>
    <Hero />
    <Bonuses />
    <Offers />
    <Comments />
    <Features />
<!--    <SpecialOffer />-->
  </div>
</template>

<script>
import Hero from "@/components/Hero";
import Bonuses from "@/components/Bonuses";
import Offers from "@/components/Offers";
import Comments from "@/components/Comments";
import Features from "@/components/Features";
//import SpecialOffer from "@/components/SpecialOffer";

export default {
name: "HomePage",
  components: {
    Hero,
    Bonuses,
    Offers,
    Comments,
    Features,
    // SpecialOffer,
  }
}
</script>


<template>
  <section id="offers" class="Wrapper Wrapper--max Offers">
    <h3 class="Title Title--center Offers-Title">
      Sports Betting <span class="Color"> Offers </span>
    </h3>
    <div v-if="isUK" class="Wrapper Wrapper--min Offers-Items">
      <div class="Offer" v-for="item in offersUK" :key="item.id">
        <a :href="addParams(item.link)" target="_blank" class="Offer-Image" >
          <img :src="require(`@/assets/img/${item.icon}`)" alt="" :class="item.class"/>
        </a>
        <div class="Offer-Content">
          <div class="Offer-Title" v-html="item.title"></div>
          <div class="Offer-Description">
            {{ item.description }}
          </div>
          <div class="Offer-Text">
            {{ item.text }}
          </div>
        </div>
        <div class="Offer-Footer">
          <!--          <button class="Offer-Link" :href="item.terms" @click="openTermsModal">-->
          <!--            T&C Apply-->
          <!--          </button>-->
          <a :href="addParams(item.link)" target="_blank" class="Btn Btn--outline Offer-Btn">
            Get Bonus
          </a>
        </div>
      </div>
    </div>
    <div v-else class="Wrapper Wrapper--min Offers-Items">
      <div class="Offer" v-for="item in offers" :key="item.id">
        <a :href="item.link" target="_blank" class="Offer-Image">
          <img :src="require(`@/assets/img/${item.icon}`)" alt=""/>
        </a>
        <div class="Offer-Content">
          <div class="Offer-Title" v-html="item.title"></div>
          <div class="Offer-Description">
            {{ item.description }}
          </div>
          <div class="Offer-Text">
            {{ item.text }}
          </div>
        </div>
        <div class="Offer-Footer">
          <!--          <button class="Offer-Link" :href="item.terms" @click="openTermsModal">-->
          <!--            T&C Apply-->
          <!--          </button>-->
          <a :href="item.link" target="_blank" class="Btn Btn--outline Offer-Btn">
            Get Bonus
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import Terms from "@/components/Terms";
import {addParams, isUK} from "../utils";

export default {
  name: 'Hero',
  data() {
    return {
      offersUK: [
        {
          id: '1',
          icon: 'william-hill-logo.svg',
          title: 'Get a £50 Casino',
          description: 'When You Stake £10',
          text: 'Welcome Bonus',
          terms: '',
          link: 'https://wildsub.com/GjJpcD?sub_id_8=4802',
        },
        {
          id: '2',
          icon: 'coral-sport.png',
          title: 'Bet £5 get £20',
          description: '',
          text: 'Welcome Bonus',
          terms: '',
          link: 'https://wildsub.com/GjJpcD?sub_id_8=4797',
        },
        {
          id: '3',
          icon: '888sport-logo.png',
          title: '£30 in free bets',
          description: '',
          text: 'Welcome Bonus',
          terms: '',
          link: 'https://wildsub.com/GjJpcD?sub_id_8=4798',
          class: 'Offer-Image--size'
        },
        {
          id: '4',
          icon: 'grosvenor-logo.svg',
          title: 'Double The Odds Any Sport.<br/> Any Bet. Any Odds',
          description: '',
          text: 'Welcome Bonus',
          terms: '',
          link: 'https://wildsub.com/GjJpcD?sub_id_8=4799',
        },
        {
          id: '5',
          icon: 'ladbrokers.png',
          title: 'Bet £5 get £20',
          description: '',
          text: 'Welcome Bonus',
          terms: '',
          link: 'https://wildsub.com/GjJpcD?sub_id_8=4800',
        },
      ],
      offers: [
        /*{
          id: '1',
          icon: 'ladbrokers.png',
          title: 'Bet £5 Get £30',
          description: '',
          text: 'Welcome Bonus',
          terms: '',
          link: 'https://www.betvictor.com/aff/bet-5-get-30-e?btagid=91728186&btag=a_49189b_4539c_&aff[…]keapp_94_3391_000_ir_web_betvictor_uniongoogle&utm_term=5-30',
        },
        {
          id: '2',
          icon: 'betvictor.svg',
          title: 'Bet £5 Get £30',
          description: '',
          text: 'Welcome Bonus',
          terms: '',
          link: 'https://www.betvictor.com/offers/matchedbet_ireland_blackmore?btagid=91745533&btag=a_[…]pp_94_3391_000_ir_web_betvictor_uniongoogle&utm_term=100-100',
        },*/
      ]
    }
  },
  computed: {
    isUK
  },
  methods: {
    addParams,
    openTermsModal() {
      this.$modal.show(
        Terms,
        {},
        {width: 1010, height: 'auto', adaptive: true, scrollable: true},
        {},
      );
    },
  },
}
</script>

<style lang="scss">
.Offers {
  margin-bottom: 60px;

  @media (min-width: $screen-m) {
    margin-bottom: 80px;
  }

  @media (min-width: $screen-l) {
    margin-bottom: 100px;
  }

  &-Title {
    margin-bottom: 41px;
  }

  &-Items {
    border-top: 1px solid var(--color-border);
  }
}

.Offer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 22px 5px;
  border-bottom: 1px solid var(--color-border);

  @media (min-width: $screen-m) {
    flex-wrap: nowrap;
    align-items: center;
  }

  &-Image {
    order: 1;
    width: 129px;

    @media (min-width: $screen-m) {
      order: 0;
      width: 144px;
    }

    @media (min-width: $screen-l) {
      width: 174px;
    }
  }

  &-Content {
    position: relative;
    margin-right: auto;
    margin-bottom: 10px;

    @media (min-width: $screen-m) {
      margin-bottom: 0;
      padding: 0 27px;
      border-left: 1px solid var(--color-border);
    }

    @media (min-width: $screen-l) {
      padding: 0 42px;
    }
  }

  &-Title {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-main1);

    @media (min-width: $screen-m) {
      font-size: 16px;
    }

    @media (min-width: $screen-l) {
      font-size: 18px;
    }
  }

  &-Description {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-title);

    @media (min-width: $screen-l) {
      font-size: 16px;
    }
  }

  &-Text {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-ghost);

    @media (min-width: $screen-l) {
      font-size: 16px;
    }
  }

  &-Footer {
    order: 2;
    display: flex;
    width: 100%;

    @media (min-width: $screen-m) {
      width: auto;
    }
  }

  &-Link {
    order: 2;
    margin-left: 14px;
    font-size: 12px;
    text-decoration: underline;
    color: var(--color-ghost);

    @media (min-width: $screen-m) {
      order: 0;
      margin-right: 16px;
      margin-left: 0;
    }

    @media (min-width: $screen-l) {
      font-size: 14px;
    }
  }

  &-Image--size {
    width: 79px;
    display: block;
    margin: 0 auto;

    @media (min-width: $screen-m) {
      width: 84px;
    }

    @media (min-width: $screen-l) {
      width: 84px;
    }
  }
}
</style>

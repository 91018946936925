<template>
  <div class="Terms">
    <h3 class="Terms-Title">
      Terms & Conditions
    </h3>
    <div class="Terms-Updated">
      Last Updated: June 10, 2020
    </div>
    <div class="Terms-Section">
      <div class="Terms-SectionTitle">
        Agreement to these Terms
      </div>
      <p class="Terms-Item">
        When it comes to the 180 additional Free Spins that are a part of the welcome package. A minimum of 20 UR/USD/CAD/AUD/USDT/NZD, 200 NOK, 1,000 RUB, 0.15 BCH, 0.75 LTC, 10,000 Dogecoin, 0.15 ETH, 80 PLN, 2,000 JPY, 19 USDT, or 0.006 BTC is required to receive the free spins. You also need to wager the amount once within 24 hours.
      </p>
      <p class="Terms-Item">
        To activate your free spins you must access and open the game within 1 day of them being credited, otherwise they will expir.
      </p>
      <p class="Terms-Item">
        Kindly note that you have to have made 4 deposits prior to participating in the Wednesday Free Spins promotion
        to be eligible for the free spins. These 4 deposits do not include the ones that are needed for claiming the promotional offer.
      </p>
      <p class="Terms-Item">
        When it comes to the 180 additional Free Spins that are a part of the welcome package. A minimum of 20 UR/USD/CAD/AUD/USDT/NZD, 200 NOK, 1,000 RUB, 0.15 BCH, 0.75 LTC, 10,000 Dogecoin, 0.15 ETH, 80 PLN, 2,000 JPY, 19 USDT, or 0.006 BTC is required to receive the free spins. You also need to wager the amount once within 24 hours.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Terms',
}
</script>

<style lang="scss">
.Terms {
  padding: 40px 52px;
  background: var(--color-bg);

  &-Section {
    counter-reset: section;
  }

  &-Title {
    margin-bottom: 5px;
    color: var(--color-title);
  }

  &-Updated {
    margin-bottom: 28px;
    color: var(--color-text);
  }

  &-SectionTitle {
    font-size: 14px;
    text-transform: uppercase;
    color: var(--color-title);
  }

  &-Item {
    position: relative;
    padding-left: 30px;
    font-size: 14px;
    line-height: 23px;
    color: var(--color-text);

    &:before {
      counter-increment: section;
      content: counter(section)".";
      position: absolute;
      left: 0;
      color: var(--color-title);
    }
  }
}

</style>

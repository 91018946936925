<template>
  <section class="Hero">
    <picture class="Hero-Image">
      <source
        media="(max-width: 340px)"
        :srcset="require(`@/assets/img/hero_320.png`)"
      />
      <source
        media="(max-width: 460px)"
        :srcset="require(`@/assets/img/hero_460.png`)"
      />
      <source
        media="(max-width: 768px)"
        :srcset="require(`@/assets/img/hero_768.png`)"
      />
      <source
        media="(max-width: 960px)"
        :srcset="require(`@/assets/img/hero_960.png`)"
      />
      <source
        media="(max-width: 1248px)"
        :srcset="require(`@/assets/img/hero_1248.png`)"
      />
      <img
        :srcset="require(`@/assets/img/hero_1920.png`)"
        alt=""
      />
    </picture>
    <div class="Hero-Content">
      <h1 class="Title Hero-Title">
        Get

      <span class="Color">
        free<br/> from 20£ to 50£
      </span><br/>
        to your first deposit
      </h1>
      <a href="#"
         class="Btn Btn--color Hero-Btn"
         @click.prevent="scrollTo"
      >
        get bonus
      </a>
    </div>
  </section>
</template>
<script>
import { scrollTo } from "../utils";

export default {
  name: 'Hero',
  methods: {
    scrollTo
  }
}
</script>

<style lang="scss">
.Hero {
  position: relative;
  margin-bottom: 46px;

  &-Content {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;

    @media (min-width: $screen-xs) {
      left: 50%;
      width: auto;
      padding-bottom: 77px / 768px * 100%;
      text-align: left;
    }

    @media (min-width: $screen-xl) {
      padding-bottom: 156px;
    }
  }

  &-Image {
    display: block;
    text-align: center;
  }

  &-Title {
    margin-bottom: 24px;
  }

  &-Btn {
    text-transform: uppercase;
  }
}
</style>

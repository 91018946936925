<template>
  <VueSlider class="Bonuses" v-bind="options">
    <div v-for="(bonus, index) in bonuses"
         :key="index"
      class="Bonuses-Item Bonus"
    >
      <div class="Wrapper Wrapper--min Bonus-Wrapper">
        <h2 class="Title Bonus-Title">
          <a :href="addParams(bonus.link)" target="_blank" class="Bonus-H2Link">{{bonus.title}}</a>
        </h2>
        <div class="Bonus-Content">
          <div class="Bonus-Text" v-html="bonus.text"></div>
          <a :href="addParams(bonus.link)" target="_blank" class="Bonus-Link">
            <img :src="require(`@/assets/img/${bonus.icon}`)"
                 class="Bonus-LinkImg"
                 alt=""
            >
          </a>
        </div>
      </div>
    </div>
  </VueSlider>
</template>

<script>
import VueSlider from '@/components/Slider.vue';
import {addParams} from "../utils";

export default {
  name: "Bonuses",
  components: {
    VueSlider,
  },
  data() {
    return {
      bonuses: [
        {
          title: "£30 in free bets",
          link: "https://wildsub.com/GjJpcD?sub_id_8=4798",
          text: "Our exclusive welcome offer. Register & login, then <span class=\"Color\">make your first deposit</span>.Place your first bet of <span class=\"Color\">£10 or more. Get £30 in Free Bets</span> upon bet settlement.",
          icon: "888sport-logo.png"
        },
        {
          title: "Bet £5 Get £30",
          link: "https://wildsub.com/GjJpcD?sub_id_8=4800",
          text: "Make your first deposit of £10 or more and <span class=\"Color\">Get £30 for free.</span> Try your Luck",
          icon: "ladbrokers.png"
        },
      ],
      options: {
        items: 1,
        loop: true,
        margin: 0,
        nav: true,
        dots: true,
      },
    }
  },
  methods: {
    addParams
  }
}
</script>

<style lang="scss">
.Bonuses {
  max-width: 1248px;
  margin-left: auto;
  margin-bottom: 106px;
  margin-right: auto;

  &-Item {
    text-align: left;
  }
}

.Bonus {
  &-Wrapper {
    padding-top: 52px;
    padding-bottom: 52px;
    background: var(--color-bg) url(~@/assets/img/bonus1.png) no-repeat bottom right;
    text-align: center;

    @media (min-width: $screen-m) {
      padding: 53px 98px;
      text-align: left;
    }

    @media (min-width: $screen-l) {
      padding: 53px 40px 50px 66px;
    }
  }

  &-Title {
    margin-bottom: 18px;
  }

  &-Content {
    display: flex;
    justify-content: space-between;
  }

  &-Text {
    width: 430px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-title);

    @media (min-width: $screen-m) {
      font-size: 16px;
    }

    @media (min-width: $screen-l) {
      font-size: 18px;
    }
  }

  &-H2Link {
    color: var(--color-main1);
  }

  &-Link {
    max-width: 100px;
    width: 100%;
  }

  .Pari {
    max-width: 200px;
  }
}

</style>

<template>
  <section class="Wrapper Wrapper--max Features">
    <h3 class="Title Title--center Features-Title">
      Online <span class="Color">Sportbook</span>
    </h3>
    <div class="Features-Text">
      Not only are there fantastic sports to bet on, but this is where you’ll find gameplay that is designed to intrigue and satisfy every player.
    </div>
    <div class="Features-Items Wrapper Wrapper--min">
      <div class="Feature">
        <div class="Feature-Image">
          <img src="@/assets/img/feature1.svg" alt="" class="Feature-Icon">
        </div>
        <div class="Feature-Content">
          <div class="Feature-Title">
            Sportbook
          </div>
          <div class="Feature-Text">
            Seeped in a rich tapestry of tantalising sports betting action, along with entertainment unrivalled, BigBetDeal is indeed the place to be.
          </div>
        </div>
      </div>
      <div class="Feature">
        <div class="Feature-Image">
          <img src="@/assets/img/feature3.svg" alt="" class="Feature-Icon">
        </div>
        <div class="Feature-Content">
          <div class="Feature-Title">
            Features
          </div>
          <div class="Feature-Text">
            As soon as you have signed up and joined in on the action that is part and parcel of BigBetDeal, there are only more great moments in store.
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

export default {
  name: 'Features'
}
</script>

<style lang="scss">
.Features {
  margin-bottom: 100px;

  @media (min-width: $screen-m) {

  }

  &-Title {
    margin-bottom: 18px;
  }

  &-Text {
    margin: 0 auto 40px auto;
    font-size: 14px;
    line-height: 1.5;
    text-align: center;
    color: var(--color-title);

    @media (min-width: $screen-m) {
      width: 566px /768px * 100%;
      font-size: 16px;
    }

    @media (min-width: $screen-l) {
      margin: 0 auto 60px auto;
      font-size: 18px;
    }

    @media (min-width: $screen-xl) {
      width: 623px /1248px * 100%;
    }
  }

  &-Items {
    border-top: 1px solid var(--color-border);
  }
}

.Feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0;
  border-bottom: 1px solid var(--color-border);
  text-align: center;

  @media (min-width: $screen-m) {
    flex-direction: row;
    padding: 20px;
    text-align: left;
  }

  @media (min-width: $screen-l ) {
    padding: 24px 50px 24px 25px;
  }

  &-Image {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 105px;
    height: 105px;
    background: var(--color-bg);
    border-radius: 50%;

    @media (min-width: $screen-m) {
      margin-right: 31px;
    }

    @media (min-width: $screen-l) {
      margin-right: 53px;
    }
  }

  &-Icon {
    width: 57px;
  }

  &-Title {
    margin-bottom: 5px;
    font-size: 22px;
    font-weight: 700;
    color: var(--color-title)
  }

  &-Text {
    font-size: 16px;
    color: var(--color-text)
  }
}
</style>

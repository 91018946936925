<template>
  <section class="Comments">
    <div class="Wrapper Wrapper--max">
      <VueSlider v-bind="options">
        <div class="Comments-Item Comment">
          <div class="Wrapper Wrapper--min Comment-Wrapper">
            <div class="Comment-Text">
              I’ve been in this office a long time and I’ve never regretted it. The site is very convenient, you can place bets even with closed eyes, the winnings are paid quickly and without delay.
            </div>
            <div class="Comment-Author">
              <span class="Comment-AuthorName">Jane N.,</span> <span class="Comment-AuthorStatus">player</span>
            </div>
          </div>
        </div>
        <div class="Comments-Item Comment">
          <div class="Wrapper Wrapper--min Comment-Wrapper">
            <div class="Comment-Text">
              Was reading the reviews when aye was waiting for 12000 withdrawal got it no problem 1 day wait spot on.
            </div>
            <div class="Comment-Author">
              <span class="Comment-AuthorName">Boris N.,</span> <span class="Comment-AuthorStatus">player</span>
            </div>
          </div>
        </div>
        <div class="Comments-Item Comment">
          <div class="Wrapper Wrapper--min Comment-Wrapper">
            <div class="Comment-Text">
              I took me just a week to complete my verification, I have been depositing and withdrawing for weeks now with zero hassles.
              Their odd is the best in the industry, for me.
            </div>
            <div class="Comment-Author">
              <span class="Comment-AuthorName">John N.,</span> <span class="Comment-AuthorStatus">player</span>
            </div>
          </div>
        </div>
        <div class="Comments-Item Comment">
          <div class="Wrapper Wrapper--min Comment-Wrapper">
            <div class="Comment-Text">
              I won £30,000 last night at 1am and sent my i d to be veriffied the same time. I had the money in my account at 5 pm
              So quick and painless
              U have nothing to worry about
            </div>
            <div class="Comment-Author">
              <span class="Comment-AuthorName">Smith,</span> <span class="Comment-AuthorStatus">player</span>
            </div>
          </div>
        </div>
      </VueSlider>
    </div>
  </section>
</template>

<script>
import VueSlider from '@/components/Slider.vue';

export default {
  name: "Comments",
  components: {
    VueSlider,
  },
  data() {
    return {
      options: {
        items: 1,
        loop: true,
        margin: 0,
        nav: true,
        dots: true,
      },
    }
  },
}
</script>

<style lang="scss">
.Comments {
  margin-top: -281px;
  margin-bottom: 35px;
  padding-top: 281px;
  padding-bottom: 50px;
  background: url('~@/assets/img/lines-bg.png') no-repeat bottom right;

  &-Title {
    margin-bottom: 39px;
  }
}

.Comment {
  &-Wrapper {
    padding: 36px 0;
    background: var(--color-bg);

    @media (min-width: $screen-m) {
      padding: 36px 66px;
    }

    @media (min-width: $screen-l) {
      padding: 36px 98px;
    }
  }

  &-Text {
    position: relative;
    margin-bottom: 18px;
    font-size: 14px;
    line-height: 1.5;
    color: var(--color-title);

    &:before {
      content: '';
      display: block;
      width: 25px;
      height: 22px;
      margin: 0 auto 16px;
      text-align: center;
      color: var(--color-main1);
      background-image: url("data:image/svg+xml,%3Csvg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24.8906 0L20.25 13.0312H25.2656V22.5469H15.8438V13.0312L20.9531 0H24.8906ZM9.04688 0L4.40625 13.0312H9.42188V22.5469H0V13.0312L5.10938 0H9.04688Z' fill='%23FFB31F'/%3E%3C/svg%3E%0A");

      @media (min-width: $screen-m) {
        position: absolute;
        left: 0;
      }
    }

    @media (min-width: $screen-m) {
      padding-left: 50px;
    }

    @media (min-width: $screen-l) {
      font-size: 16px;
    }
  }

  &-Author {
    position: relative;
    padding-top: 10px;
    font-size: 14px;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 0;
      width: 25px;
      height: 2px;
      font-size: 96px;
      background: var(--color-border2);
      transform: translateX(-50%);
    }
  }

  &-AuthorName {
    font-weight: 700;
    color: var(--color-title);
  }

  &-AuthorStatus {
    color: var(--color-ghost);
  }
}

</style>
